<template>
  <div class="custom">
    <div class="header-block">
      <slot name="header" />
    </div>

    <main class="content-block">
      <slot name="content" />
    </main>
  </div>
</template>

<style scoped lang="scss">
.custom {
  display: flex;
  flex-direction: column;
  height: 100%;

  .header-block {
    z-index: 1;
  }
  .content-block {
    flex-grow: 1;
  }
}
</style>
